<div class="container dashboard-branches flex flex-col">
  <div class="container-title px-2 mx-auto mb-8 mb-md-12 text-center">
    <h1 class="vcim-headline-1 mb-3">
      {{ "website.dashboard.branches.title" | translate }}
    </h1>
    <span class="vcim-subtitle">
      {{ "website.dashboard.branches.subtitle" | translate }}
    </span>
  </div>

  <ng-container *ngIf="branchesList$ | async as branchesList">
    <ng-container
      *ngTemplateOutlet="
        branchesList.length > 3 ? carousel : simple;
        context: { branchesList: branchesList }
      "
    >
    </ng-container>
  </ng-container>

  <button
    [routerLink]="'/' + Paths.Branches| localize"
    mat-stroked-button
    color="primary"
    class="vcim-button md-button color-primary mx-auto w-full w-sm-auto"
  >
    {{ "website.actions.see-more" | translate }}
  </button>
</div>

<ng-template #carousel let-branchesList="branchesList">
  <div
    class="slider-container mb-4 mb-sm-6 mb-lg-0 mx-0 px-4 mx-lg--8 mx-xl--4 px-xl-0"
  >
    <owl-carousel-o [options]="customOptions">
      <ng-container *ngFor="let slide of branchesList; let index; fieldTrackBy">
        <ng-template class="slide" carouselSlide [id]="index.toString()">
          <div class="branches-card px-4 py-10 h-full w-full">
            <div
              class="d-flex pointer row h-full"
              [routerLink]="'/branches/' + slide.slug | localize"
            >
              <loading-image
                [image]="
                  '/portal/' +
                  slide.photoPath +
                  (slide.photo.endsWith('.svg') ? '/' : '/medium_') +
                  slide.photo
                "
                [alt]="slide.title"
                *ngIf="slide.photo"
                [styles]="{
                  'object-fit': 'cover',
                  'object-position': 'center'
                }"
                class="col-4"
              >
              </loading-image>
              <div class="p-3 col-8">
                <div class="vcim-headline-5 mb-4 f-18">
                  <ngx-dynamic-hooks
                    [options]="{ sanitize: false }"
                    class="vcim-inner-html inner-html-title"
                    [content]="removeHtmlTags(slide.title)"></ngx-dynamic-hooks>

                </div>
                <div class="flex" *ngIf="slide.workingHours.length">
                  <i class="vcim-icon ic-time f-20 pr-2"> </i>
                  <div class="vcim-body-2 f-16 d-flex flex-col">
                    <span
                      *ngFor="let item of slide.workingHours; let i = index"
                      class="mb-1"
                    >
                      {{ item.title }} {{ item.description }}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ng-template>
      </ng-container>
    </owl-carousel-o>
  </div>
</ng-template>

<ng-template #simple let-branchesList="branchesList">
  <div
    class="px-0 px-sm-0 vcim-grid vcim-grid-size-1 vcim-grid-size-2-sm vcim-grid-size-3-lg gap-4 gap-md-8 gap-xl-8 mb-16"
  >
    <div class="" *ngFor="let branch of branchesList; let i = index">
      <div class="branches-card py-10 h-full">
        <div
          class="d-flex pointer row h-full"
          [routerLink]="'/branches/' + branch.slug | localize"
        >
          <loading-image
            [image]="
              '/portal/' +
              branch.photoPath +
              (branch.photo.endsWith('.svg') ? '/' : '/medium_') +
              branch.photo
            "
            [alt]="branch.title"
            *ngIf="branch.photo"
            [styles]="{
              'object-fit': 'cover',
              'object-position': 'center'
            }"
            class="h-full col-4"
          >
          </loading-image>
          <div class="p-3 col-8">
            <div class="vcim-headline-5 mb-3 f-18">
              <ngx-dynamic-hooks
                [options]="{ sanitize: false }"
                class="vcim-inner-html inner-html-title"
                [content]="removeHtmlTags(branch.title)"></ngx-dynamic-hooks>
            </div>
            <div class="flex" *ngIf="branch.workingHours.length">
              <i class="vcim-icon ic-time f-20 pr-2"> </i>
              <div class="vcim-body-2 f-16 d-flex flex-col">
                <span
                  *ngFor="let item of branch.workingHours; let i = index"
                  class="mb-1"
                >
                  {{ item.title }} {{ item.description }}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #showSpinner>
  <div style="height: 100px" class="my-10 d-flex justify-center">
    <mat-spinner></mat-spinner>
  </div>
</ng-template>
