<section class="vcim-container container pt-8 pt-lg-16 mb-6 pb-10 pb-xl-16">
  <div *ngIf="doctorInfo$ | async as doctorInfo; else showSpinner">
    <div class="mb-6 d-flex justify-between items-center">
      <div class="flex flex-col">
        <h1 class="vcim-headline-2 mb-3">{{ doctorInfo.firstName }} {{ doctorInfo.lastName }}</h1>
        <p class="vcim-body-1">{{ doctorInfo.position }}</p>
      </div>

      <button
        type="submit"
        mat-flat-button
        *ngIf="(user$ | async) as user; else showLogin"
        color="primary"
        [disabled]="!user.verified"
        (click)="createAppointment(doctorInfo.id, doctorInfo.slug)"

        class="vcim-button md-button color-primary">
        {{ 'website.doctors.create-appointment' | translate }}
      </button>

      <ng-template #showLogin>
        <button
          mat-flat-button
          color="primary"
          [routerLink]="'/login' | localize"
          [queryParams]="{ doctorId: doctorInfo.id, doctorSlug: doctorInfo.slug }"
          [disabled]="doctorInfo.positionCode === 'laboratory_doctor'"
          class="vcim-button md-button color-primary">
          {{ 'website.doctors.create-appointment' | translate }}
        </button>
        </ng-template>
    </div>
    <div class="row mx-0 mx-sm--3 mx-md--6">
      <div class="col-12 col-sm-4 px-0 px-sm-3 px-md-6 flex flex-col mb-5">
        <div class="px-16 px-sm-0">
          <app-doctors-card [doctor]="{ photo: doctorInfo.photo, photoPath: doctorInfo.photoPath }"></app-doctors-card>
        </div>
        <a
          class="vcim-headline-5 mt-2 d-flex items-center whitespace-nowrap"
          *ngIf="doctorInfo.email"
          [href]="'mailto:' + doctorInfo.email"
        >
          <i class="vcim-icon ic-mail f-20 pr-2"> </i>
          {{ doctorInfo.email }}
        </a>
      </div>

      <div class="col-12 col-sm-8 px-0 px-sm-3 px-md-6">
        <ngx-dynamic-hooks
          [options]="{ sanitize: false }"
          class="vcim-body-1 vcim-inner-html"
          [content]="doctorInfo.description"></ngx-dynamic-hooks>
      </div>
    </div>
  </div>
</section>

<ng-template #showSpinner>
  <div style="height: 100px" class="my-10 d-flex justify-center">
    <mat-spinner></mat-spinner>
  </div>
</ng-template>
