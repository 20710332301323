<mat-accordion class="vcim-accordion d-flex flex-col" *ngIf="priceList$ | async as priceList; else showSpinner">
  <mat-expansion-panel hideToggle [expanded]="i === 0" class="mb-4" *ngFor="let price of priceList; index as i">
    <mat-expansion-panel-header class="px-4 px-md-10 py-3 py-md-6">
      <mat-panel-title class="d-flex justify-between items-center">
        <div class="accordion-header-label vcim-headline-5 px-3 py-1 d-flex items-center">
          <i class="vcim-icon ic-location f-20 pr-2"> </i>
          <ngx-dynamic-hooks
            [options]="{ sanitize: false }"
            class="vcim-inner-html inner-html-title"
            [content]="removeHtmlTags(price.branch)"></ngx-dynamic-hooks>
        </div>
        <span class="accordion-icon d-flex items-center justify-center animated">
          <i class="vcim-icon d-flex"></i>
        </span>
      </mat-panel-title>
    </mat-expansion-panel-header>
    <div class="p-4 px-md-10 pb-md-10 pt-md-5">
      <div
        class="price-item d-flex justify-between items-end py-3 flex-col xs-flex-row selectable pointer animated"
        *ngFor="let srv of price.services"
        (click)="selectPrice(srv, price.branch, price.id)">
        <span class="vcim-body-1 break-word w-full">
          <ngx-dynamic-hooks
            [options]="{ sanitize: false }"
            class="vcim-inner-html inner-html-title"
            [content]="srv.title"></ngx-dynamic-hooks>
        </span>
        <span class="price pt-3 xs:pt-0 pl-0 pl-xs-5 pl-md-10 vcim-headline-5 flex-shrink-0">
          <span>
            {{ srv.price | thousands }}
          </span>
          AMD
        </span>
      </div>
    </div>
  </mat-expansion-panel>
</mat-accordion>

<ng-template #showSpinner>
  <div style="height: 100px" class="my-10 d-flex justify-center">
    <mat-spinner></mat-spinner>
  </div>
</ng-template>
